import React from "react";
import "@styles/Title.css";

const Title = ({ className, children }) => {
    return (
        <div className={`${className}`}>
            <div className={`title mb-5 inline-block`}>
                <h2 className="h2 text-xl md:text-2xl">{children}</h2>
                <div className="opacity-50 flex mt-2">
                    <hr className={`ml-2 rounded-l-full border-gray-300`} />
                    <hr className="border-ccyan" />
                    <hr className={`rounded-r-full border-gray-300`} />
                </div>
            </div>
        </div>
    );
};
export default Title;
