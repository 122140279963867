import React from "react";
import GenericImage from "./CloudinaryImage";

const Banner = props => {
    const { filename, title, subtitle, height } = props;
    return (
        <>
            <GenericImage
                filename={filename}
                className={`smallbanner bg-cover bg-center ${
                    height ? `${height}` : "h-116"
                } bg-gray-400`}
            >
                <div className="flex h-full bg-black bg-opacity-25">
                    <div className="container lg:px-0 flex">
                        <div className="my-auto">
                            <h1 className="font-montserrat font-bold text-3xl sm:text-4xl lg:text-5xl text-shadow-lg text-white">
                                {title}
                            </h1>
                            {subtitle && subtitle.trim().length > 0 && (
                                <h2 className="text-white opacity-85 mt-2 font-montserrat leading-6 sm:leading-8 sm:text-xl lg:text-2xl lg:leading-10 text-shadow-lg">
                                    {subtitle}
                                </h2>
                            )}
                        </div>
                    </div>
                </div>
            </GenericImage>
        </>
    );
};

export default Banner;
