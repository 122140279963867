import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { toast } from "react-toastify";
// import { useState } from "react";
import { FaEnvelope, FaCheck } from "react-icons/fa";

const App = props => {
    // const { pageContext } = props;
    // const { item } = pageContext;

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <>
            <Seo
                title="Solicitud de Eliminación de Datos"
                description="Tu Privacidad Es Importante Para Nosotros"
            />

            <Layout>
                <SmallBanner
                    title={"Solicitud de Eliminación de Datos"}
                    subtitle={"Tu Privacidad Es Importante Para Nosotros"}
                    filename={`bgs/bgRemoveUserProfile`}
                />

                <div className="container lg:px-0 py-20">
                    <Title>Solicitud de eliminación de datos</Title>
                    <Text className="space-y-5">
                        <p>
                            En iOpos Academia, nos preocupamos profundamente por tu privacidad y
                            estamos comprometidos en proteger tus datos personales.
                        </p>
                        <p>
                            Si deseas que eliminemos tus datos de nuestra base de datos, por favor
                            completa el siguiente formulario proporcionando tu dirección de correo
                            electrónico.
                        </p>
                        <p>
                            Una vez que hayamos verificado tu solicitud, procederemos con la
                            eliminación de tus datos en un plazo de 7 días laborables.
                        </p>
                    </Text>

                    <form
                        onSubmit={handleSubmit}
                        className="md:p-10 md:rounded md:shadow md:border md:border-gray-300 mt-10"
                    >
                        <div className="grid grid-cols-1 gap-5">
                            <div className="space-y-2">
                                <label htmlFor="email" className={styles.label}>
                                    <FaEnvelope className="text-gray-600 w-5 h-5" />
                                    <span>Correo electrónico *:</span>
                                </label>

                                <input
                                    id="email"
                                    type="email"
                                    // onChange={e => setCorrectas(e.target.value)}
                                    required={true}
                                    // max={100}
                                    // min={0}
                                    className={styles.input}
                                    // value={correctas}
                                    value=""
                                />
                            </div>

                            <div className="space-y-2">
                                <label htmlFor="motivo" className={styles.label}>
                                    <FaCheck className="text-gray-600 w-5 h-5" />
                                    <span>Motivo:</span>
                                </label>

                                <textarea id="motivo" className={styles.input}>
                                    -
                                </textarea>
                            </div>

                            <div className="mt-2 w-full">
                                <button type="submit" className={styles.submit}>
                                    Enviar solicitud
                                </button>
                            </div>
                        </div>
                    </form>

                    {/*
                    
                    Formulario de Solicitud de Eliminación de Datos
*Correo Electrónico:* [Campo de entrada de correo electrónico]
*Motivo (opcional):* [Campo de entrada para que los usuarios expliquen su motivo]
Instrucciones:
1. Proporciona tu dirección de correo electrónico en el campo correspondiente.
2. Si lo deseas, puedes proporcionar un motivo adicional para tu solicitud.
3. Haz clic en "Enviar Solicitud".
Nota Importante:
- La eliminación de tus datos será irreversible y no podrás recuperar la información que hemos eliminado.- Es posible que mantengamos un registro mínimo de tu solicitud para cumplir con las regulaciones aplicables.
- Te notificaremos por correo electrónico una vez que hayamos completado la eliminación de tus datos.
Gracias por confiar en [Nombre de tu empresa o sitio web]. Si tienes alguna pregunta o necesitas más información, por favor contáctanos a [dirección de correo de contacto] o visita nuestra página de Política de Privacidad para obtener detalles adicionales sobre cómo manejamos tus datos personales.
**Formulario de Solicitud de Eliminación de Datos**[Botón "Enviar Solicitud"]
                    */}
                </div>
            </Layout>
        </>
    );
};

export default App;

const styles = {
    label: "w-full block font-montserrat text-gray-700 font-semibold uppercase flex items-center space-x-2 text-sm",
    input: "font-montserrat w-full rounded-md bg-gray-200 text-gray-700 border border-gray-300 px-5 py-2 block",
    spanComment: "text-xs text-gray-500 font-montserrat mb-5",
    submit: "font-montserrat font-semibold text-center rounded-md shadow-md hover:shadow-xl py-4 bg-cpurple text-white text-xs uppercase duration-300 w-full block cursor-pointer",
};
